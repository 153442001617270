

















import { NftModel } from "@/models/nft-model";
import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Prop } from "vue-property-decorator";

@Observer
@Component({
  components: {},
})
export default class CharacterAttribute extends Vue {
  @Prop() character!: NftModel;
  @Prop({ default: false }) smallGap!: boolean;
}
